// src/redux/slices/registerSlice.ts
import { Address } from "@/src/@types/shipping";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OAuthCredential } from "firebase/auth";


interface RegisterState {
  name: string;
  firstName: string;
  lastName: string;
  userName: string;
  email: string;
  loadRegister: boolean;
  error: string;
  address: Address;
  phoneNumber: string;
  credential: OAuthCredential | null
  password: string;
}

const initialState: RegisterState = {
  name: "",
  firstName: "",
  lastName: "",
  userName: "",
  email: "",
  phoneNumber: "",
  loadRegister: false,
  error: "",
  address: {
    city: "",
    country: "",
    state: "",
    street1: "",
    zip: "",
  },
  credential: null,
  password: "",
};

// slice for registering
const registerSlice = createSlice({
  name: "register",
  initialState,
  reducers: {
    setName(state, action: PayloadAction<string>) {
      state.name = action.payload;
    },
    setFirstName(state, action: PayloadAction<string>) {
      state.firstName = action.payload;
    },
    setLastName(state, action: PayloadAction<string>) {
      state.lastName = action.payload;
    },
    setUserName(state, action: PayloadAction<string>) {
      state.userName = action.payload;
    },
    setEmail(state, action: PayloadAction<string>) {
      state.email = action.payload;
    },
    setPassword(state, action: PayloadAction<string>) {
      state.password = action.payload;
    },
    setLoadRegister(state, action: PayloadAction<boolean>) {
      state.loadRegister = action.payload;
    },
    setError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
    setAddress(state, action: PayloadAction<Address>) {
      state.address = action.payload;
    },
    setPhoneNumber(state, action: PayloadAction<string>) {
      state.phoneNumber = action.payload
    },
    resetRegisterState(state) {
      console.log('reset called')
      state.address = initialState.address;
      state.email = initialState.email;
      state.error = initialState.error;
      state.firstName = initialState.firstName;
      state.lastName = initialState.lastName;
      state.loadRegister = initialState.loadRegister;
      state.name = initialState.name;
      state.phoneNumber = initialState.phoneNumber;
      state.userName = initialState.userName;
      state.password = initialState.password;

      state= {...initialState};
    },
    setCredential(state, action: PayloadAction<OAuthCredential>) {
      state.credential = action.payload;
    }
  },
});

export const {
  setName,
  setFirstName,
  setLastName,
  setUserName,
  setEmail,
  setLoadRegister,
  setError,
  resetRegisterState,
  setAddress,
  setPhoneNumber,
  setPassword,
} = registerSlice.actions;

export default registerSlice.reducer;
