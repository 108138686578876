import { emptyMarketplaces } from "../app/(navbar)/inventory/modal/useOneClickListing";
import { seller } from "./seller";
import { GetInventoryValueResponse } from "./stats";

export interface AuthFormValues {
  email: string;
  password: string;
  name?: string;
}

export interface ButtonProps {
  title: string;
  onPress: () => void;
  bgColor?: string;
  textColor?: string;
  width?: string;
  border?: boolean;
  disabled?: boolean;
  className?: string;
  textSize?: string;
  borderRadius?: string;
  fontWeight?: string;
  style?: string;
  prefixIconName?: string;
  suffixIconName?: string;
  loading?: boolean;
}

export interface GradientButtonProps extends Omit<ButtonProps, "bgColor"> {
  gradient: string[];
}

export interface List {
  title: string;
  description: string;
}
export interface SliderProps {
  list: Array<List>;
}

export interface LoaderProps {
  loading: boolean;
  text?: string;
}

export interface SocialButtonProps {
  iconName: string;
  onPress: () => void;
  disabled?: boolean;
  bg?: string;
}

export interface SocialLoginButtonProps extends SocialButtonProps {
  textColor: string;
  title: string;
}

export interface ResetPasswordFormValues {
  email: string;
}

export interface UserState {
  user: seller | undefined;
  stats?: GetInventoryValueResponse | undefined;
  id: string | null;
  name: string | null;
  email: string | null;
  username: string | null;
  otpCode: string | null;
  otpId: string | null;
  phoneNumber: string | null;
  address: string | null;
  isVerified: boolean;
  isOnboarding: boolean;
  hasCreatedListing: boolean;
  ref: string | null;
  hasReviewed: boolean;
  following: string[];
  followers: string[];
  lastCheckedEbayDelisted?: string | undefined | null;
}

export interface PublishPlatformsState {
  platformsData: {
    [key: string]: {
      success: boolean | null;
      error: boolean | null;
      isLoading: boolean;
    };
  };
}

export interface AddressInputProps {
  placeholderText?: string | undefined;
  onChange: (address: string, zipCode: string, details: any | null) => void;
  styles?: object;
  onPress?: any;
}

export interface imagesArray {
  height: number;
  id: string;
  name: string;
  path: string;
  uri: string;
  width: number;
}
export interface Product {
  name?: string | null;
  price?: string | null;
  description?: string | null;
  brand?: string | null;
  image?: string | null;
  images?: imagesArray[];
  quantity?: string | null;
  condition?: string | null;
  showProductDetails?: boolean;
  showProductPlatform?: boolean;
  showProductImages?: boolean;
  information?: string;
}

//Camera Types //

export enum CameraType {
  Front = "front",
  Back = "back",
}

export type TorchMode = "on" | "off";

export type FlashMode = "on" | "off" | "auto";

export type FocusMode = "on" | "off";

export type ZoomMode = "on" | "off";

export type CaptureData = {
  uri: string;
  name: string;
  // Android only
  id?: string;
  path?: string;
  height?: number;
  width?: number;
  // iOS only
  size?: number;
};

export type Permission = {
  title: string;
  message: string;
  buttonPositive: string;
};

export type CameraApi = {
  capture: () => Promise<CaptureData>;
  requestDeviceCameraAuthorization: () => Promise<boolean>;
  checkDeviceCameraAuthorizationStatus: () => Promise<boolean>;
};

export const marketplaceInfo = {
  AMAZON: {
    displayName: "Amazon",
    defaultId: "ATVPDKIKX0DER",
    leafCategoryOnly: false,
    hasStaticSpecs: true,
    hasDynamicSpecs: false,
    calculateFees: (listingPrice: number) => 0.12 * listingPrice,
  },
  EBAY: {
    displayName: "eBay",
    defaultId: "EBAY_US",
    leafCategoryOnly: true,
    hasStaticSpecs: true,
    hasDynamicSpecs: true,
    calculateFees: (listingPrice: number) => 0.12 * listingPrice,
  },
  FACEBOOK: {
    displayName: "Facebook",
    defaultId: "",
    leafCategoryOnly: true,
    hasStaticSpecs: true,
    hasDynamicSpecs: true,
    calculateFees: 0,
  },
  MERCARI: {
    displayName: "Mercari",
    defaultId: "",
    leafCategoryOnly: true,
    hasStaticSpecs: true,
    hasDynamicSpecs: true,
    calculateFees: 0,
  },
  DEPOP: {
    displayName: "Depop",
    defaultId: "",
    leafCategoryOnly: true,
    hasStaticSpecs: true,
    hasDynamicSpecs: true,
    calculateFees: (listingPrice: number) => 0.1 * listingPrice * 0.33 + 0.45,
  },
  OFFERUP: {
    displayName: "OfferUp",
    defaultId: "",
    leafCategoryOnly: true,
    hasStaticSpecs: true,
    hasDynamicSpecs: true,
    calculateFees: (listingPrice: number) => {
      const fee = 0.129 * listingPrice;
      return fee < 1.99 ? 1.99 : fee;
    },
  },
  GOAT: {
    displayName: 'GOAT',
    defaultId: '',
    leafCategoryOnly: true,
    hasStaticSpecs: true,
    hasDynamicSpecs: true,
    calculateFees: (listingPrice: number) => (0.095 * listingPrice) + 5,
  },
  SELLRAZE: {
    displayName: 'SellRaze',
    defaultId: '',
    leafCategoryOnly: true,
    hasStaticSpecs: true,
    hasDynamicSpecs: true,
    calculateFees: 0,
  }
} as const;

export type Marketplace = keyof typeof marketplaceInfo;

export function marketplaceDisplayName(marketplace: Marketplace) {
  return marketplaceInfo[marketplace].displayName;
}

export const marketplaces = Object.keys(marketplaceInfo).sort() as readonly Marketplace[];

export type ApiCondition =
  | "new"
  | "new-open-box"
  | "new-with-defects"
  | "certified-refurbished"
  | "seller-refurbished"
  | "used"
  | "used-like-new"
  | "used-very-good"
  | "used-good"
  | "used-acceptable"
  | "not-working";

export type ApiLengthUnit = "INCHES" | "CENTIMETERS" | "MILLIMETERS" | "FEET" | "METERS" | "YARDS";

export type ApiWeightUnit = "POUNDS" | "OUNCES" | "KILOGRAMS" | "GRAMS";

export type ApiPIDType = "ASIN" | "EAN" | "GTIN" | "ISBN" | "JAN" | "MINSAN" | "UPC";

export type PostInventoryProduct = {
  tempInventory: { marketplace_shipping: never[] };
  inventory_id: string;
  listing_id: string;
  restricted_allocation: number;
  created_at: string;
  updated_at: string;
  type: string;
  product_name: string;
  quantity: number;
  platforms: {
    marketplaces: {
      marketplace: Marketplace;
      marketplace_ids: string[];
      active: boolean;
    }[];
  };
  general_information: {
    has_variation: boolean;
    has_brand_name: boolean;
    imported: boolean;
    has_pid: boolean;
    brand_name: string;
    sku: string;
    size: string;
    external_pid: string;
    external_pid_type: ApiPIDType | "";
    model_number: string;
    description: string;
    notes: string;
    item_purchase_date: string;
  };
  categories: {
    category_type_array: category_type_array[];
  };
  images: {
    image_urls: {
      image_url: string;
      selected: boolean;
    }[];
  };
  product_specifications: product_specifications;
  variations: {
    variation_type: string;
    size: {
      type: "size";
      values: string[] | null;
    };
    color: {
      type: "color";
      values: string[] | null;
    };
    style: {
      type: "style";
      values: string[] | null;
    };
  };
  shipping: {
    item_dimensions: {
      length: number;
      width: number;
      height: number;
      unit: ApiLengthUnit | "";
    };
    package_dimensions: {
      length: number;
      width: number;
      height: number;
      unit: ApiLengthUnit | "";
    };
    weight: {
      item_weight: number;
      package_weight: number;
      weight_unit: ApiWeightUnit | "";
    };
    warehouse_location: string[];
    marketplace_shipping: {
      marketplace: "EBAY" | "AMAZON" | "FACEBOOK" | "MERCARI" | "DEPOP" | "POSHMARK" | "OFFERUP";
      carrier_name: string;
      display_name: string;
      id: string;
      shipping_time: string;
      rate_maximum: number;
      rate_minimum: number;
      pricing_unit?: string; // Default: "USD"
      zip_code: string;
      max_weight: string;
      subtitle: string;
      suggested: boolean;
    }[];
  };
  pricing: {
    condition: ApiCondition | "";
    item_cost: number;
    currency: string;
    marketplace_pricing: {
      marketplace: Marketplace;
      shipping_services?: {
        carrier_name: string;
        display_name: string;
        id: string;
        rate_maximum: number;
        rate_minimum: number;
        shipping_time: string;
        pricing_unit: string;
        zip_code: string;
      }[];
      currency?: string;
      presale_price?: number;
      listing_price: number | null;
      platform_fees?: number | null;
      default_platform_fees?: number | null;
    }[];
    default_listing_price: number | null;
    amazon_fba: boolean;
  };
};

export const emptyPostInventoryProduct: PostInventoryProduct = {
  tempInventory: { marketplace_shipping: [] },
  inventory_id: "",
  listing_id: "",
  restricted_allocation: 0,
  created_at: "",
  updated_at: "",
  product_name: "",
  quantity: 0,
  platforms: {
    marketplaces: emptyMarketplaces,
  },
  type: "draft",
  general_information: {
    has_variation: false,
    has_brand_name: false,
    has_pid: false,
    imported: false,
    brand_name: "",
    sku: "",
    external_pid: "",
    external_pid_type: "",
    model_number: "",
    description: "",
    notes: "",
    item_purchase_date: "",
    size: "",
  },
  categories: {
    category_type_array: [],
  },
  images: {
    image_urls: [],
  },
  product_specifications: {
    marketplace_specs: [],
  },
  variations: {
    variation_type: "",
    size: {
      type: "size",
      values: [],
    },
    color: { type: "color", values: [] },
    style: { type: "style", values: [] },
  },
  shipping: {
    item_dimensions: {
      height: 0,
      width: 0,
      length: 0,
      unit: "",
    },
    package_dimensions: {
      height: 0,
      width: 0,
      length: 0,
      unit: "",
    },
    weight: {
      item_weight: 0,
      package_weight: 0,
      weight_unit: "",
    },
    warehouse_location: [],
    marketplace_shipping: [],
  },
  pricing: {
    condition: "",
    item_cost: 0,
    currency: "",
    marketplace_pricing: [],
    default_listing_price: 0,
    amazon_fba: false,
  },
};




export type category_type_array = {
  category_id: string[];
  category_name: string;
  marketplace: Marketplace;
};

export type image = {
  uri: string;
  id: string;
};

export type product_specifications = {
  marketplace_specs: marketplace_specs[];
};

export type marketplace_specs = {
  marketplace: string;
  marketplace_id: string;
  specifications: specification;
};

export type specification = {
  parent: string;
  values: string[];
  aspects: Record<string, aspect>;
};

export type aspect = {
  name: string;
  dataType: string;
  isRequired: boolean;
  nestedValues: aspectEnum[];
  hasNestedValue: boolean;
  displayName: string;
  moreInfo: string;
  defaultValue: aspectEnum;
};

export type aspectEnum = {
  value: string;
  displayName: string;
};


export type GetInventoryProduct = {
  inventory_id: string;
  listing_id: string;
  product_name: string;
  quantity: number;
  restricted_allocation: number;
  created_at: string;
  updated_at: string;

  platforms: {
    marketplaces: ({
      active: boolean;
    } & PostInventoryProduct["platforms"]["marketplaces"][number])[];
  };
  // platforms: PostInventoryProduct["platforms"] & { marketplaces: {active: boolean}[] };
  general_information: PostInventoryProduct["general_information"];
  categories: PostInventoryProduct["categories"];
  images: PostInventoryProduct["images"];
  product_specifications: PostInventoryProduct["product_specifications"];
  variations: PostInventoryProduct["variations"];
  shipping: PostInventoryProduct["shipping"];
  pricing: PostInventoryProduct["pricing"];
  stats: {
    units_sold: number;
    revenue: number;
    profit: number;
    margin: number;
  };
  marketplace_errors: unknown[];
};

export type GetOffersPriceResponse = {
  payload: {
    asin: string;
    itemCondition: string;
    status: string;
    summary: {
      totalOfferCount: number;
      lowestPrices: {
        condition: string;
        fulfillmentChannel: string;
        offerType: string;
        quantityTier: number;
        quantityDiscountType: string;
        landedPrice: MoneyType;
        listingPrice: MoneyType;
        shipping: MoneyType;
      }[];
      buyBoxPrices: {
        condition: string;
        fulfillmentChannel: string;
        offerType: string;
        quantityTier: number;
        quantityDiscountType: string;
        landedPrice: MoneyType;
        listingPrice: MoneyType;
        shipping: MoneyType;
      }[];
      competitivePriceThreshold: MoneyType;
    };
  };
  // Include properties from ApiErrorResponse here
};

// Define MoneyType here
type MoneyType = {
  currencyCode: string;
  amount: string;
};

export type ScanDetailsType = {
  imageUrl: string;
  asin: string;
  brand: string;
  title: string;
  price: string;
};

export interface Root {
  inventory_id: string;
  listing_id: string;
  product_name: string;
  quantity: number;
  restricted_allocation: number;
  created_at: string;
  updated_at: string;
  platforms: Platforms;
  general_information: GeneralInformation;
  categories: Categories;
  images: Images;
  product_specifications: ProductSpecifications;
  variations: Variations;
  shipping: Shipping;
  pricing: Pricing;
  stats: Stats;
  marketplace_errors: any;
}

export interface Platforms {
  marketplaces: any;
}

export interface GeneralInformation {
  has_variation: boolean;
  has_brand_name: boolean;
  has_pid: boolean;
  imported: boolean;
  brand_name: string;
  sku: string;
  external_pid: string;
  external_pid_type: string;
  item_type_name: string;
  model_number: string;
  model_name: string;
  description: string;
  notes: string;
  item_purchase_date: string;
  marketplace_specific_titles: any;
  marketplace_specific_descriptions: any;
}

export interface Categories {
  category_type_array: any;
}

export interface Images {
  image_urls: any;
}

export interface ProductSpecifications {
  marketplace_specs: any;
}

export interface Variations {
  variation_type: string;
  size: Size;
  color: Color;
  style: Style;
}

export interface Size {
  type: string;
  values: any;
}

export interface Color {
  type: string;
  values: any;
}

export interface Style {
  type: string;
  values: any;
}

export interface Shipping {
  item_dimensions: ItemDimensions;
  package_dimensions: PackageDimensions;
  weight: Weight;
  warehouse_location: any;
  marketplace_shipping: any;
}

export interface ItemDimensions {
  length: number;
  width: number;
  height: number;
  unit: string;
}

export interface PackageDimensions {
  length: number;
  width: number;
  height: number;
  unit: string;
}

export interface Weight {
  item_weight: number;
  package_weight: number;
  weight_unit: string;
}

export interface Pricing {
  condition: string;
  item_cost: number;
  currency: string;
  marketplace_pricing: any;
  amazon_fba: boolean;
  default_listing_price: number;
}

export interface Stats {
  units_sold: number;
  revenue: number;
  profit: number;
  margin: number;
  sales: any;
}


